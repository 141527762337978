import './loading.css'
function Loading() {
  return (
    <>
      <div id='loading-dark-container'>
        <div class="loader"></div>
      </div>
    </>
  );
}

export default Loading;
